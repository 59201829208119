import {FilterSelectProps, FilterSelect} from '../../../shared/components'
import {useUserPlantList} from '../../hooks'

export const PlantFilter = <TName extends string>({
  translationPrefix,
  ...props
}: Omit<FilterSelectProps<string, TName>, 'options'>) => {
  const options = useUserPlantList().map(({plantId, plantName}) => ({
    value: plantId,
    label: plantName
  }))

  return <FilterSelect {...props} translationPrefix={translationPrefix} options={options} />
}
