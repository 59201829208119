import {TASKS_ASSIGNED_TO_ME_PARAM_KEY} from '@hconnect/common/hproduce/consts'

const TasksQuickFilterKeys = {
  ASSIGNED_TO_ME: TASKS_ASSIGNED_TO_ME_PARAM_KEY,
  SOURCES: 'sources'
} as const

export const TasksFilterKey = {
  TITLE: 'title',
  STATUSES: 'statuses',
  CATEGORIES: 'categories',
  PRIORITIES: 'priorities',
  PLANT: 'plant',
  PROCESS_STAGES: 'processStages',
  EQUIPMENT: 'equipment',
  MAIN_EQUIPMENT: 'mainEquipment'
} as const

export type TasksFilterKeyName = (typeof TasksFilterKey)[keyof typeof TasksFilterKey]

export const TasksPageParamKey = {
  ...TasksFilterKey,
  ...TasksQuickFilterKeys
} as const
