import {AppName} from '@hconnect/common/hproduce'

import {TaskFilterDto, TasksFiltersState, TaskSource} from '../types'

const SERVER_APP_NAME_MAP: Record<TaskSource, AppName> = {
  [TaskSource.COCKPIT]: 'Cockpit',
  [TaskSource.EASY_POM]: 'easyPOM'
}

export const mapToAppName = (source: TaskSource): AppName =>
  SERVER_APP_NAME_MAP[source] ?? 'HProduce'

export const mapTasksFiltersParams = (filters?: TasksFiltersState): TaskFilterDto | undefined => {
  if (!filters) {
    return undefined
  }

  const {plant, mainEquipment, equipment, ...restFilters} = filters

  return {
    ...restFilters,
    plantIds: plant ? [plant] : undefined,
    mainEquipmentId: mainEquipment,
    equipmentId: equipment
  }
}
