import {WhiteList} from '@hconnect/common/hproduce'
import {orderBy} from 'lodash'
import React, {useMemo} from 'react'

type UseWhiteListContextReturn = WhiteList

type WhiteListContextProviderProps = {
  initialState: WhiteList
  children?: React.ReactNode
}

const WhiteListContext = React.createContext<UseWhiteListContextReturn | undefined>(undefined)

export const WhiteListContextProvider: React.FC<WhiteListContextProviderProps> = ({
  initialState,
  children
}) => {
  return <WhiteListContext.Provider value={initialState}>{children}</WhiteListContext.Provider>
}

export function useWhiteListContext(): UseWhiteListContextReturn {
  const ctx = React.useContext(WhiteListContext)
  if (ctx === undefined) {
    throw new Error('useWhiteListContext was used outside the scope of a WhiteListContextProvider!')
  } else {
    return ctx
  }
}

type UseUserPlantListReturn = {plantId: string; plantName: string}[]

export function useUserPlantList(): UseUserPlantListReturn {
  const whiteList = useWhiteListContext()
  const {optionsPerPlant} = whiteList

  const allPlants = useMemo(
    () =>
      orderBy(
        Object.keys(optionsPerPlant).map((plantId) => ({
          plantId,
          plantName: optionsPerPlant[plantId][0]?.plantName
        })),
        'plantName'
      ),
    [optionsPerPlant]
  )

  return allPlants
}
