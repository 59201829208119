import {AddFilterButton, MenuItemTooltipTitle} from '@hconnect/common/components'
import {getTranslationKey} from '@hconnect/common/utils'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FilterTextField} from '../../../shared/components'
import {TasksFilterKey, TasksFilterKeyName} from '../../consts'
import {type TasksFiltersState} from '../../types'
import {isTasksFilterKey} from '../../utils'

import {PlantFilter} from './PlantFilter'
import {TaskCategoryFilter} from './TaskCategoryFilter'
import {TaskEquipmentSearchFilter} from './TaskEquipmentSearchFilter'
import {TaskMainEquipmentSearchFilter} from './TaskMainEquipmentSearchFilters'
import {TaskPriorityFilter} from './TaskPriorityFilter'
import {TaskProcessStageFilter} from './TaskProcessStageFilter'
import {TaskStatusFilter} from './TaskStatusFilter'

const plantRequiringFilters: TasksFilterKeyName[] = [
  TasksFilterKey.PROCESS_STAGES,
  TasksFilterKey.MAIN_EQUIPMENT,
  TasksFilterKey.EQUIPMENT
]

type TasksFiltersStateValue = TasksFiltersState[keyof TasksFiltersState]

type Props = {
  translationPrefix: string
  filters: TasksFiltersState
  onFiltersChange: (filters: TasksFiltersState) => void
}

type FilterOption = {
  value: TasksFilterKeyName
  label: string
  disabled?: boolean
  tooltipTitle?: React.ReactNode
}

export const TasksFilters: React.FC<Props> = ({translationPrefix, filters, onFiltersChange}) => {
  const [visibleFilters, setVisibleFilters] = React.useState<TasksFilterKeyName[]>(
    getInitialVisibleFilters(filters)
  )
  const {t} = useTranslation()
  const plantFilterValue = filters[TasksFilterKey.PLANT]

  const filtersOptions: FilterOption[] = [
    {
      value: TasksFilterKey.TITLE,
      label: t(getTranslationKey('shiftEvent.label.title', translationPrefix))
    },
    {
      value: TasksFilterKey.CATEGORIES,
      label: t(getTranslationKey('shiftEvent.label.category', translationPrefix))
    },
    {
      value: TasksFilterKey.STATUSES,
      label: t(getTranslationKey('shiftEvent.label.status', translationPrefix))
    },
    {
      value: TasksFilterKey.PRIORITIES,
      label: t(getTranslationKey('shiftEvent.label.priority', translationPrefix))
    },
    {
      value: TasksFilterKey.PLANT,
      label: t(getTranslationKey('tasksTable.label.plant', translationPrefix))
    },
    {
      value: TasksFilterKey.PROCESS_STAGES,
      label: t(getTranslationKey('shiftEvent.label.processStage', translationPrefix)),
      disabled: !plantFilterValue,
      tooltipTitle: !plantFilterValue && (
        <PlantRequiredTooltip
          translationPrefix={translationPrefix}
          disabledFilter={t(getTranslationKey('shiftEvent.label.processStage', translationPrefix))}
        />
      )
    },
    {
      value: TasksFilterKey.MAIN_EQUIPMENT,
      label: t(getTranslationKey('shiftEvent.label.mainEquipment', translationPrefix)),
      disabled: !plantFilterValue,
      tooltipTitle: !plantFilterValue && (
        <PlantRequiredTooltip
          translationPrefix={translationPrefix}
          disabledFilter={t(getTranslationKey('shiftEvent.label.mainEquipment', translationPrefix))}
        />
      )
    },
    {
      value: TasksFilterKey.EQUIPMENT,
      label: t(getTranslationKey('shiftEvent.label.equipment', translationPrefix)),
      disabled: !plantFilterValue,
      tooltipTitle: !plantFilterValue && (
        <PlantRequiredTooltip
          translationPrefix={translationPrefix}
          disabledFilter={t(getTranslationKey('shiftEvent.label.equipment', translationPrefix))}
        />
      )
    }
  ]

  const handleFilterChange = (key: TasksFilterKeyName, value?: TasksFiltersStateValue) => {
    onFiltersChange({...filters, [key]: value})
  }

  const handlePlantFilterChange = (key: TasksFilterKeyName, plantId?: string) => {
    const newFilters = {...filters}
    if (!plantId) {
      plantRequiringFilters.forEach((filter) => {
        newFilters[filter] = undefined
      })
    }
    onFiltersChange({...newFilters, [key]: plantId})
  }

  const handlePlantFilterClear = () => {
    handlePlantFilterChange(TasksFilterKey.PLANT, undefined)
    setVisibleFilters(
      visibleFilters
        .filter((filter) => filter !== TasksFilterKey.PLANT)
        .filter((filter) => !plantRequiringFilters.includes(filter))
    )
  }

  const clearFilter = (key: TasksFilterKeyName) => {
    onFiltersChange({...filters, [key]: undefined})
    setVisibleFilters(visibleFilters.filter((filter) => filter !== key))
  }

  const handleVisibleFiltersChange = (value: TasksFilterKeyName[]) => {
    setVisibleFilters((prevFilters) => {
      const newVisibleFilters = !value.includes(TasksFilterKey.PLANT)
        ? value.filter((filter) => !plantRequiringFilters.includes(filter))
        : value
      const filtersToClear = prevFilters.filter((filter) => !newVisibleFilters.includes(filter))
      const newFilters = {...filters}

      filtersToClear.forEach((filter) => {
        newFilters[filter] = undefined
      })

      onFiltersChange(newFilters)

      return newVisibleFilters
    })
  }

  return (
    <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
      {visibleFilters.includes(TasksFilterKey.TITLE) && (
        <FilterTextField
          name={TasksFilterKey.TITLE}
          label={t(getTranslationKey('shiftEvent.label.title', translationPrefix))}
          value={filters[TasksFilterKey.TITLE]}
          onChange={handleFilterChange}
        />
      )}
      {visibleFilters.includes(TasksFilterKey.CATEGORIES) && (
        <TaskCategoryFilter<TasksFilterKeyName>
          name={TasksFilterKey.CATEGORIES}
          label={t(getTranslationKey('shiftEvent.label.category', translationPrefix))}
          value={filters[TasksFilterKey.CATEGORIES]}
          onChange={handleFilterChange}
          clearFilter={clearFilter}
          translationPrefix={translationPrefix}
        />
      )}
      {visibleFilters.includes(TasksFilterKey.STATUSES) && (
        <TaskStatusFilter<TasksFilterKeyName>
          name={TasksFilterKey.STATUSES}
          label={t(getTranslationKey('shiftEvent.label.status', translationPrefix))}
          value={filters[TasksFilterKey.STATUSES]}
          onChange={handleFilterChange}
          clearFilter={clearFilter}
          translationPrefix={translationPrefix}
        />
      )}
      {visibleFilters.includes(TasksFilterKey.PRIORITIES) && (
        <TaskPriorityFilter<TasksFilterKeyName>
          name={TasksFilterKey.PRIORITIES}
          label={t(getTranslationKey('shiftEvent.label.priority', translationPrefix))}
          value={filters[TasksFilterKey.PRIORITIES]}
          onChange={handleFilterChange}
          clearFilter={clearFilter}
          translationPrefix={translationPrefix}
        />
      )}
      {visibleFilters.includes(TasksFilterKey.PLANT) && (
        <PlantFilter<TasksFilterKeyName>
          name={TasksFilterKey.PLANT}
          label={t(getTranslationKey('tasksTable.label.plant', translationPrefix))}
          value={filters[TasksFilterKey.PLANT]}
          onChange={handlePlantFilterChange}
          clearFilter={handlePlantFilterClear}
          translationPrefix={translationPrefix}
        />
      )}
      {plantFilterValue && (
        <>
          {visibleFilters.includes(TasksFilterKey.PROCESS_STAGES) && (
            <TaskProcessStageFilter<TasksFilterKeyName>
              name={TasksFilterKey.PROCESS_STAGES}
              label={t(getTranslationKey('shiftEvent.label.processStage', translationPrefix))}
              value={filters[TasksFilterKey.PROCESS_STAGES]}
              onChange={handleFilterChange}
              clearFilter={clearFilter}
              translationPrefix={translationPrefix}
              plantId={plantFilterValue}
            />
          )}
          {visibleFilters.includes(TasksFilterKey.MAIN_EQUIPMENT) && (
            <TaskMainEquipmentSearchFilter<TasksFilterKeyName>
              name={TasksFilterKey.MAIN_EQUIPMENT}
              label={t(getTranslationKey('shiftEvent.label.mainEquipment', translationPrefix))}
              value={filters[TasksFilterKey.MAIN_EQUIPMENT]}
              onChange={handleFilterChange}
              clearFilter={clearFilter}
              plantId={plantFilterValue}
              translationPrefix={translationPrefix}
            />
          )}
          {visibleFilters.includes(TasksFilterKey.EQUIPMENT) && (
            <TaskEquipmentSearchFilter<TasksFilterKeyName>
              name={TasksFilterKey.EQUIPMENT}
              label={t(getTranslationKey('shiftEvent.label.equipment', translationPrefix))}
              value={filters[TasksFilterKey.EQUIPMENT]}
              onChange={handleFilterChange}
              clearFilter={clearFilter}
              plantId={plantFilterValue}
              translationPrefix={translationPrefix}
            />
          )}
        </>
      )}
      <Box sx={{textWrap: 'nowrap'}}>
        <AddFilterButton
          data-test-id={'tasks-add-filter'}
          options={filtersOptions}
          onChange={handleVisibleFiltersChange}
          values={visibleFilters}
          buttonLabel={t(getTranslationKey('shiftEvent.label.addFilter', translationPrefix))}
        />
      </Box>
    </Box>
  )
}

const PlantRequiredTooltip = ({
  translationPrefix,
  disabledFilter
}: {
  translationPrefix: string
  disabledFilter: string
}) => {
  const {t} = useTranslation()
  return (
    <MenuItemTooltipTitle
      title={t(getTranslationKey('filters.filterUnavailable', translationPrefix))}
      message={t(getTranslationKey('filters.filterDependencyMessage', translationPrefix), {
        disabledFilter,
        requiredFilter: t(getTranslationKey('tasksTable.label.plant', translationPrefix))
      })}
    />
  )
}

const getInitialVisibleFilters = (filters: TasksFiltersState): TasksFilterKeyName[] => {
  return Object.keys(filters).filter(isTasksFilterKey)
}
