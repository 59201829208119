import {RemoveFilterButton} from '@hconnect/common/components'
import {Box, MenuItem, TextField} from '@mui/material'

import {selectFieldOnBlueSx} from './filterStyles'

export type FilterSelectOption<T extends string | number> = {
  value: T
  label: string
}

export type FilterSelectProps<TOptionValue extends string | number, TName extends string> = {
  name: TName
  label: string
  options: FilterSelectOption<TOptionValue>[]
  value?: TOptionValue
  onChange: (name: TName, value?: TOptionValue) => void
  clearFilter: (keyName: TName) => void
  enableSelectAll?: boolean
  getOptionLabel?: (option: FilterSelectOption<TOptionValue>) => React.ReactNode
  translationPrefix: string
  disabled?: boolean
  helperText?: string
  'data-test-id'?: string
}

export const FilterSelect = <TOptionValue extends string | number, TName extends string>({
  name,
  label,
  options,
  value,
  onChange,
  clearFilter,
  getOptionLabel = ({label}) => label,
  translationPrefix,
  disabled = false,
  helperText,
  'data-test-id': dataTestId
}: FilterSelectProps<TOptionValue, TName>) => {
  const fieldDataTestId = dataTestId ?? `${name}-filter`

  return (
    <TextField
      variant="filled"
      sx={selectFieldOnBlueSx}
      data-test-id={fieldDataTestId}
      fullWidth
      label={label}
      required={false}
      onChange={(event) => {
        const val = event.target.value as unknown as TOptionValue | undefined
        if (!val) onChange(name, undefined)
        else onChange(name, val)
      }}
      value={value ?? []}
      select
      disabled={disabled}
      helperText={helperText}
    >
      {options.map((option) => (
        <MenuItem
          data-test-id={`${fieldDataTestId}-${option.value}`}
          key={option.value}
          value={option.value}
          selected={option.value === value}
        >
          {getOptionLabel(option)}
        </MenuItem>
      ))}
      <Box>
        <RemoveFilterButton
          keyName={name}
          removeFilter={(key) => clearFilter(key as TName)}
          translationPrefix={translationPrefix}
        />
      </Box>
    </TextField>
  )
}
