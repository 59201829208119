import {EventProcessStageTitle} from '@hconnect/common/components/shiftEventFormFields'
import {useProcessStages} from '@hconnect/common/hooks/useProcessStages'

import {FilterMultiSelect, FilterMultiSelectProps} from '../../../shared/components'
import {FILTER_VALUE_SEPARATOR} from '../../../shared/consts'

export const TaskProcessStageFilter = <TName extends string>(
  props: Omit<FilterMultiSelectProps<string, TName>, 'options'> & {plantId: string}
) => {
  const {plantId} = props
  const {data: processStagesMap} = useProcessStages(plantId)
  const options = processStagesMap
    ? Array.from(processStagesMap).map(([value, label]) => ({value, label}))
    : []

  return (
    <FilterMultiSelect
      {...props}
      options={options}
      getOptionLabel={({value}) => <EventProcessStageTitle stage={value} plantId={plantId} />}
      renderValue={(value) => {
        return value
          .map((processStage) => (processStagesMap ? processStagesMap.get(processStage) : ''))
          .join(FILTER_VALUE_SEPARATOR)
      }}
    />
  )
}
