import {getTranslationKey} from '@hconnect/common/utils'
import {Autocomplete, MenuItem, Paper, TextField} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {selectFieldOnBlueSx} from './filterStyles'

export type FilterAutocompleteOption<T extends string> = {
  value: T
  label: string
}

export type FilterSearchAutocompleteProps<
  TName extends string,
  TValue = FilterAutocompleteOption<string>
> = {
  name: TName
  label: string
  value?: TValue
  onChange: (name: TName, value: TValue | undefined) => void
  clearFilter: (name: TName) => void
  options: TValue[]
  getOptionLabel: (option: TValue) => string
  getOptionValue: (option: TValue) => string
  isOptionEqualToValue: (option: TValue, value: TValue) => boolean
  searchTerm?: string
  onSearchChange?: (searchQuery: string) => void
  isLoading?: boolean
  translationPrefix?: string
  dataTestId?: string
}

const paper = (props) => <Paper elevation={8} sx={{minWidth: '350px'}} {...props} />

export const FilterSearchAutocomplete = <
  TName extends string,
  TValue = FilterAutocompleteOption<string>
>({
  name,
  label,
  value: selectedOption,
  onChange,
  clearFilter,
  options,
  getOptionLabel,
  getOptionValue,
  isOptionEqualToValue,
  searchTerm,
  onSearchChange,
  isLoading,
  translationPrefix,
  dataTestId
}: FilterSearchAutocompleteProps<TName, TValue>) => {
  const fieldDataTestId = dataTestId ?? `${name}-filter`
  const {t} = useTranslation()

  return (
    <Autocomplete
      data-test-id={dataTestId}
      value={selectedOption ?? null}
      multiple={false}
      onChange={(_, next, reason) => {
        if (reason === 'clear') {
          if (selectedOption || searchTerm) {
            onChange(name, undefined)
            onSearchChange?.('')
          } else {
            clearFilter(name)
          }
        } else {
          onChange(name, next ?? undefined)
        }
      }}
      onInputChange={(_, newValue, reason) => {
        if (reason === 'input') {
          onSearchChange?.(newValue)
        }
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t(getTranslationKey('pleaseStartTyping', translationPrefix))}
          fullWidth={false}
          onChange={(event) => onSearchChange?.(event.target.value)}
          sx={selectFieldOnBlueSx}
          variant="filled"
          label={label}
          data-test-id={fieldDataTestId}
        />
      )}
      PaperComponent={paper}
      renderOption={(props, option) => {
        return (
          <MenuItem
            {...props}
            key={`${getOptionValue(option)}-${getOptionLabel(option)}`}
            data-test-id={`${fieldDataTestId}-${getOptionValue(option)}`}
          >
            {getOptionLabel(option)}
          </MenuItem>
        )
      }}
      forcePopupIcon={false}
      getOptionLabel={getOptionLabel}
      loading={isLoading}
      filterOptions={(options) => options}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  )
}
